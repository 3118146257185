<template>
	<v-sheet class="task-main-listing" style="height: calc(100vh - 88px)">
		<v-layout>
			<v-flex class="py-0">
				<v-select
					v-model="status"
					class="listing-select"
					hide-details
					:disabled="pageLoading"
					:items="moduleSettings && moduleSettings.status"
					solo
					v-on:change="statusFilterRows($event)"
				>
					<template v-slot:item="{ item }">
						<v-list-item-action>
							<v-avatar size="26" :color="item.status_color" :text-color="item.textcolor">
								<span class="white--text">{{ item.status_count }}</span>
							</v-avatar>
						</v-list-item-action>
						<v-list-item-content>
							<v-list-item-title v-text="item.text"></v-list-item-title>
							<v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
						</v-list-item-content>
					</template>
				</v-select>
			</v-flex>
			<v-spacer></v-spacer>
			<v-flex class="py-0 my-auto d-flex text-right listing-right justify-content-end">
				<template v-if="selected.length > 0">
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon left>mdi-chevron-down</v-icon>
								Bulk Action
							</v-btn>
						</template>
						<v-list class="py-0">
							<template v-for="(item, i) in listingBulkAction">
								<v-list-item link :key="i" v-on:click="doAction(item.value)">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingBulkAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-btn
						depressed
						:disabled="pageLoading"
						tile
						v-on:click="clearSelection"
						color="red lighten-1"
						class="text-white"
					>
						<v-icon left> mdi-close </v-icon>
						Clear Selections
					</v-btn>
				</template>
				<template v-else>
					<v-flex class="d-flex justify-content-end flex-wrap mr-1">
						<!-- <div class="mr-2 fw-600 my-auto">Filter By</div> -->
						<!-- <div class="filterTagSelect ml-3">
							<v-autocomplete
								v-model="priorityList[0]"
								:items="priorityList"
								item-text="text"
								item-value="value"
								:menu-props="{ bottom: true, offsetY: true }"
								prepend-inner-icon="mdi-circle"
								:color="filter_label == 'High' ? 'red' : filter_label == 'Medium' ? 'orange' : 'blue'"
								:class="
									filter_label == 'High'
										? 'red-icon'
										: filter_label == 'Medium'
										? 'orange-icon'
										: 'blue-icon'
								"
								outlined
								hide-details
								class="filterSelect"
								content-class="filterSelectContent"
								v-on:change="topBarFilter($event)"
							>
								<template v-slot:priorityList="data">
									<template>
										<v-list-item-icon class="mr-0 my-1">
											<v-icon
												class="mdi-18px"
												:color="data.item == 'High' ? 'red' : data.item == 'Medium' ? 'orange' : 'blue'"
												>mdi-circle</v-icon
											>
										</v-list-item-icon>
										<v-list-item-content>
											<v-list-item-title v-html="data.item"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div> -->
						<div class="ml-3">
							<v-autocomplete
								:items="moduleSettings && moduleSettings.users"
								clearable
								v-model="user_filter"
								v-on:click:clear="(defaultFilter = {}), getListing"
								:menu-props="{ bottom: true, offsetY: true }"
								style="max-width: 250px !important"
								outlined
								v-on:change="topBarFilter"
								hide-details
								item-text="display_name"
								item-value="id"
								class="mr-2"
								placeholder="Sales Person"
							>
								<template v-slot:selection="data">
									<v-chip small v-bind="data.attrs" :input-value="data.selected">
										<v-avatar left v-if="data.item.profile_img">
											<v-img v-if="data.item.profile_img" :src="data.item.profile_img"></v-img>
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-avatar>

										{{ data.item.display_name }}
									</v-chip>
								</template>
								<template v-slot:item="data">
									<template>
										<v-list-item-avatar>
											<img v-if="data.item.profile_img" :src="data.item.profile_img" />
											<v-img v-else :src="$assetURL('media/misc/no_photo_found.png')"></v-img>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title v-html="data.item.display_name"></v-list-item-title>
										</v-list-item-content>
									</template>
								</template>
							</v-autocomplete>
						</div>
					</v-flex>
					<v-btn
						v-if="getPermission('task:create')"
						depressed
						tile
						color="red darken-4"
						class="text-white"
						@click="createTask()"
						:disabled="pageLoading"
					>
						<v-icon left> mdi-plus </v-icon>
						Create
					</v-btn>
					<v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="routeSetting()"
					>
						<v-icon>mdi-cog</v-icon>
					</v-btn>

					<v-btn
						v-if="false"
						depressed
						color="blue darken-4"
						class="text-white"
						tile
						:disabled="pageLoading"
						v-on:click="viewTypedata"
					>
						<v-icon v-if="viewType">mdi-view-list</v-icon>
						<v-icon v-else>mdi-grid</v-icon>
					</v-btn>

					<!-- <v-btn
						depressed
						:disabled="pageLoading"
						color="blue darken-4"
						class="text-white"
						tile
						v-on:click="drawerFilter = true"
					>
						<v-icon>mdi-filter</v-icon>
					</v-btn> -->
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<!-- <template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-menu</v-icon>
							</v-btn>
						</template> -->
						<v-list class="py-0">
							<template v-for="(item, i) in listingAction">
								<v-list-item link :key="i" v-on:click="referess">
									<v-list-item-title
										><v-icon v-if="item.icon" left :color="item.color">{{ item.icon }}</v-icon
										>{{ item.title }}</v-list-item-title
									>
								</v-list-item>
								<v-divider v-if="listingAction[i + 1]" :key="`d` + i" class="m-0 p-0"></v-divider>
							</template>
						</v-list>
					</v-menu>
					<v-menu
						bottom
						left
						origin="center center"
						transition="slide-y-transition"
						rounded="0"
						offset-y
						max-height="400px"
						:close-on-content-click="false"
						content-class="white-background"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								depressed
								color="blue darken-4"
								class="text-white"
								tile
								:disabled="pageLoading"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-table-edit</v-icon>
							</v-btn>
						</template>
						<Draggable
							tag="ul"
							v-model="draggableThead"
							class="draggable-group"
							handle=".draggable-drag-icon"
							v-on:change="updateTable('member')"
						>
							<template v-for="cols in draggableThead">
								<li class="draggable-group-item" :key="cols.key" v-if="!cols.checkbox">
									<v-checkbox
										dense
										v-model="draggableTheadShow"
										v-bind:value="cols.key"
										:label="cols.name"
										:disabled="cols.fixed || pageLoading"
										color="blue"
										hide-details
										class="mt-0 mb-0"
										v-on:change="updateTableVisiblity('task')"
									></v-checkbox>
									<v-icon
										v-if="!pageLoading && !cols.fixed"
										class="draggable-action draggable-drag-icon"
										right
										color="blue"
										>mdi-drag</v-icon
									>
									<v-icon v-else right class="draggable-action" color="grey">mdi-drag</v-icon>
								</li>
							</template>
						</Draggable>
					</v-menu>
				</template>
			</v-flex>
		</v-layout>
		<SearchCriteria
			v-if="search_Enabled()"
			:search-fields="listingFilter"
			search-string="Task #, Title, Start Date, Due Date, Task Added, Related To and Attendees"
			v-on:advance-search="advanceSearch = true"
			v-on:close-search="resetSearch"
		></SearchCriteria>
		<template v-if="addvanceFilterAarry.length > 0">
			<v-layout class="btx-search-criteria">
				<v-flex md10>
					<h6>Search Criteria</h6>
					<ul>
						<li>
							Advance Filter
							<span class="search-text"></span>
						</li>
					</ul>
				</v-flex>
				<v-flex md2 class="text-right">
					<v-btn
						depressed
						color="red lighten-1"
						class="text-white"
						tile
						text
						small
						v-on:click="statusFilterRows('all')"
					>
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-flex>
			</v-layout>
		</template>
		<template v-if="viewType">
			<TaskKanban></TaskKanban>
		</template>
		<template v-else>
			<Table
				v-if="getPermission('task:view')"
				:page-loading="pageLoading"
				type="task"
				delete-endpoint="task/"
				detail-route="task-detail"
				is-drawer-detail
				v-on:open-drawer="open_drawer($event)"
				v-on:reload:content="filterRows"
				:status-filter-data="statusFilterData"
			></Table>
		</template>
		<ExportDialog
			endpoint="member/export?type=member&current=1"
			title="Members"
			current
			:export-dialog.sync="exportCurrentDialog"
			v-on:close="exportCurrentDialog = false"
		></ExportDialog>
		<ExportDialog
			endpoint="member/export?type=member"
			title="Members"
			:export-dialog.sync="exportDialog"
			v-on:close="exportDialog = false"
		></ExportDialog>
		<ImportDialog
			endpoint="member/import?type=member"
			title="Members"
			:import-dialog.sync="importDialog"
			v-on:close="importDialog = false"
		></ImportDialog>
		<FilterDialog
			title="Members"
			:filter-dialog.sync="advanceSearch"
			:btx-filter.sync="listingFilter"
			:btx-query.sync="btxQuery"
			v-on:do-search="doAdvanceSearch"
			v-on:close="advanceSearch = false"
		>
		</FilterDialog>
		<AdvanceFilter
			v-on:addvanceFilterData="dataFilter"
			:advanceSearch="drawerFilter"
			v-on:close="drawerFilter = false"
			search-endpoint="task"
			search-title="Task"
		></AdvanceFilter>
		<CreateTask
			v-if="create_task"
			:drawer="create_task"
			title-status="Create"
			v-on:close="create_task = false"
			:task-data="editTask"
			:attendies="attendieList"
		></CreateTask>
	</v-sheet>
</template>
<script>
import { toSafeInteger } from "lodash";
import ListingMixin from "@/core/mixins/listing.mixin";
import TaskKanban from "@/view/module/task/TaskKanban";
import CreateTask from "@/view/pages/leads/create/CreateTask";
import AdvanceFilter from "@/view/pages/leads/AdvanceFilter";
import { GET, QUERY } from "@/core/services/store/request.module";
import { TaskEventBus } from "@/core/lib/task.form.lib";
import { mapGetters } from "vuex";
import {
	SET_TBODY,
	SET_SHOWING_STRING,
	SET_THEAD,
	SET_FILTER,
	SET_STATUS,
	SET_TOTAL_PAGE,
	SET_CURRENT_PAGE,
	SET_BULK_ACTION,
	SET_ACTION,
} from "@/core/services/store/listing.module";
export default {
	name: "task-listing",
	mixins: [ListingMixin],
	data() {
		return {
			viewType: true,
			labelItems: ["High", "Medium", "Low"],
			filter_label: "High",
			/* 	user_filter: 0, */
			create_task: false,
			pageTitle: "Task",
			pageBreadcrumbs: [{ text: "task", disabled: true }],
			endpoint: "task",
			defaultFilter: {},
			settingsEndpoint: "task-setting",
			addvanceFilterAarry: [],
			statusFilterData: [],
			editTask: {},
			attendieList: {},
			drawerFilter: false,
			priorityList: [],
			status: "all",
		};
	},
	components: {
		TaskKanban,
		CreateTask,
		AdvanceFilter,
	},
	methods: {
		referess() {
			window.location.reload();
		},
		dataFilter(value) {
			this.addvanceFilterAarry = value;
		},
		open_drawer(id) {
			this.create_task = true;
			this.$store
				.dispatch(GET, { url: `single-task/${id}` })
				.then((data) => {
					this.editTask = data.tasks;
					this.attendieList = data.attendie[0];
				})
				.catch((error) => {
					console.log({ error });
				});
		},
		viewTypedata() {
			this.viewType = !this.viewType;
			this.$router.push({
				name: "task",
				query: {
					t: new Date().getTime(),
					viewType: this.viewType,
				},
			});
		},
		// getTaskStatus() {
		// 	this.$store
		// 		.dispatch(GET, { url: "task-setting" })
		// 		.then((data) => {
		// 			this.priorityList = data.priority;
		// 		})
		// 		.catch((error) => {
		// 			console.log({ error });
		// 		})
		// 		.finally(() => {
		// 			this.pageLoading = false;
		// 		});
		// },
		statusFilterRows(value) {
			this.allFiltersStatus(value);
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `task`,
					data: {
						status: value,
						all: 1,
					},
				})
				.then((data) => {
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
					this.addvanceFilterAarry = [];
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		allFiltersStatus(value) {
			this.$router.push({
				name: "task",
				query: {
					status: value,
					t: new Date().getTime(),
				},
			});
		},
		createTask() {
			this.editTask = {};
			this.attendieList = {};
			this.create_task = true;
		},
		topBarFilter() {
			this.pageLoading = true;
			this.$store
				.dispatch(QUERY, {
					url: `task`,
					data: {
						status: "all",
						user_filter: this.user_filter,
					},
				})
				.then((data) => {
					this.$router.push({
						name: "task",
						query: {
							user_filter: this.user_filter,
							t: new Date().getTime(),
							status: this.status,
							search: this.listingSearch || undefined,
						},
					});
					this.$store.commit(SET_ACTION, data.action);
					this.$store.commit(SET_BULK_ACTION, data.bulk_action);
					this.$store.commit(SET_CURRENT_PAGE, data.current_page);
					this.$store.commit(SET_TOTAL_PAGE, data.total_page);
					this.$store.commit(SET_SHOWING_STRING, data.showing_string);
					this.$store.commit(SET_STATUS, data.status);
					this.$store.commit(SET_FILTER, data.filter);
					this.$store.commit(SET_TBODY, data.tbody);
					this.$store.commit(SET_THEAD, data.thead);
				})
				.catch((error) => {
					console.log({
						error,
					});
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		routeSetting() {
			this.$router.push({
				name: "setting",
				query: {
					tab: "task",
				},
			});
		},
	},
	computed: {
		...mapGetters(["currentUser", "moduleSettings"]),
	},
	beforeMount() {
		this.user_filter = toSafeInteger(this.$route.query.user_filter);
		if ((this.currentUser.role == 1 || this.currentUser.role == 4) && !this.user_filter) {
			this.user_filter = toSafeInteger(this.$route.query.user_filter);
		}
		/* 	this.defaultFilter = {
			user_filter: this.user_filter ? this.user_filter : null,
		}; */
	},
	mounted() {
		// this.getTaskStatus();
		this.viewType = this.$route.query.viewType;
		let filterQuery = this.$route.query;

		this.$router.push({
			name: "task",
			query: {
				...filterQuery,
				viewType: this.viewType,
				t: new Date().getTime(),
			},
		});
		TaskEventBus.$on("refresh", () => {
			this.getListing();
		});
		/* this.topBarFilter(); */
	},
};
</script>
