<template>
	<div class="bg-white dbNotes-card">
		<div class="px-5 pt-5 d-flex">
			<h3>
				Notes
				<v-tooltip top content-class="custom-top-tooltip">
					<template v-slot:activator="{ on, attrs }">
						<span v-on="on" v-bind="attrs">
							<span style="color: green"> {{ checkLenght(dbNotes) }}</span>
							<!-- <span class="text-primary">{{ dbNotes.length }}</span> -->
						</span>
					</template>
					<span>Checked</span>
				</v-tooltip>
				<v-tooltip top content-class="custom-top-tooltip">
					<template v-slot:activator="{ on, attrs }">
						<span v-on="on" v-bind="attrs">
							/<span style="color: red"> {{ dbNotes.length }}</span>
							<!-- <span class="text-primary">{{ dbNotes.length }}</span> -->
						</span>
					</template>
					<span>Total</span>
				</v-tooltip>
			</h3>

			<v-spacer></v-spacer>

			<v-btn
				v-if="dbNotes.length"
				tile
				depressed
				fab
				small
				height="30"
				width="30"
				class="mr-2 blue lighten-4"
				@click="addNote()"
			>
				<v-icon size="16" icon color="blue">mdi-plus</v-icon>
			</v-btn>
		</div>
		<div class="py-2 pt-2">
			<div class="px-5 overflow-auto d-flex flex-column" style="max-height: 500px; height: 500px">
				<table class="w-100 note-table" v-if="dbNotes.length">
					<tr v-for="(row, index) in dbNotes" :key="index" class="blue lighten-5">
						<td valign="top" width="2%">
							<v-checkbox
								hide-details
								v-model="dbNotes[index].check"
								class="ma-1"
								color="blue"
								v-on:change="updateNotes"
							></v-checkbox>
						</td>
						<td class="" width="85%">
							<v-textarea
								class="note-area border-0"
								name="input-7-1"
								filled
								auto-grow
								hide-details
								rows="1"
								:disabled="dbNotes[index].check"
								:ref="`textarea-${index}`"
								v-on:keyup="updateNotes"
								v-model="row.notes_remark"
								@keypress.prevent.enter="addNoteNextLine()"
								@focus="updatedIndex(index)"
							>
							</v-textarea>
						</td>
						<td valign="top" width="5%" align="center">
							<v-btn
								tile
								depressed
								fab
								small
								style="margin-bottom: -20px !important"
								height="30"
								width="30"
								class="mr-2 red lighten-4"
								@click="removeNotes(row.id)"
							>
								<v-icon size="16" icon color="red">mdi-delete</v-icon>
							</v-btn>
						</td>
						<!-- <td valign="top" width="20px">
				<v-btn
					tile
					depressed
					fab
					small
					height="30"
					width="30"
					class="mr-2 red lighten-4"
					@click="removeNotes()"
					>
					<v-icon size="16" icon color="red">mdi-delete</v-icon>
					</v-btn>
			  </td> -->
					</tr>
				</table>
			</div>
		</div>
	</div>
</template>
<style>
.theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
	/* background-color: #ddecf7 !important; */
	background: rgb(221 221 221 / 6%) !important;
}
</style>
<script>
import { PATCH, DELETE } from "@/core/services/store/request.module";

import { filter } from "lodash";
export default {
	name: "mine-notes",
	data() {
		return {
			activeIndex: null,

			/* dbNotes: [
		{    
			notes_remark :null,
		}
		], */
			timeout: null,
			timeoutLimit: 500,
		};
	},
	props: {
		dbNotes: {
			type: Array,
			default() {
				return [];
			},
		},
		taskId: {
			type: Number,
			default: 0,
		},
		taskType: {
			type: String,
			default: null,
		},
	},
	watch: {
		dbNotes: {
			deep: true,
			handler(param) {
				if (param && param.length == 0) {
					this.dbNotes = [
						{
							notes_remark: null,
							readOnly: false,
							check: false,
						},
					];
				}
				/* 	this.disbaleData(); */
			},
		},
	},

	methods: {
		init(notes) {
			(this.dbNotes.notes_remark = notes.notes_remark ? notes.notes_remark : null),
				(this.dbNotes.id = notes.id ? notes.id : 0),
				(this.dbNotes.related_id = notes.related_id ? notes.related_id : 0),
				(this.dbNotes.related_type = notes.related_type ? notes.related_type : null);
			this.dbNotes.check = notes.check ? notes.check : 0;
		},
		checkLenght(data) {
			let activeData = filter(data, (row) => row.check);
			return activeData.length;
		},
		updateNotes() {
			const _this = this;
			const db_notes = filter(_this.dbNotes, function (param) {
				return param.notes_remark !== null;
			});

			clearTimeout(_this.timeout);

			if (db_notes.length > 0) {
				_this.timeout = setTimeout(function () {
					_this.$store
						.dispatch(PATCH, {
							url: `notes-checklist/${_this.taskType}/${_this.taskId}`,
							data: { notes: db_notes },
						})
						.then(({ data }) => {
							console.log({ data });
						})
						.catch((error) => {
							console.log({ error });
						});
				}, _this.timeoutLimit);
			}
		},
		addNote() {
			this.dbNotes.push({
				id: Date.now(),
				text: null,
				notes_remark: null,
				readOnly: false,
				check: false,
			});
			const length = this.dbNotes.length;
			this.$nextTick(() => {
				const element = this.$refs[`textarea-${length - 1}`];
				const area = element[0].$el.querySelector("textarea");
				area.focus();
			});
		},
		/*  getTaskChecklist() {
			this.$store
				.dispatch(GET, { url: `task/${this.taskId}/checklists` })
				.then((data) => {
					this.dbNotes = data;
					console.log(this.dbNotes)
				})
				.catch((error) => {
					console.log({ error });
				});
		}, */
		addNoteNextLine() {
			if (this.activeIndex >= 0) {
				this.dbNotes.splice(this.activeIndex + 1, 0, {
					id: Date.now(),
					text: null,
					notes_remark: null,
				});
				this.$nextTick(() => {
					const element = this.$refs[`textarea-${this.activeIndex + 1}`];
					const area = element[0].$el.querySelector("textarea");
					area.focus();
				});
			}
		},
		removeNotes(id) {
			if (id > 0) {
				this.$store
					.dispatch(DELETE, { url: `task/${this.taskId}/${id}` })
					.then(() => {
						this.$emit("updateTask", true);
						/* this.getTaskChecklist(); */
					})
					.catch((error) => {
						console.log({ error });
					});
			}
		},
		removeNote(index) {
			if (!this.dbNotes[index].text) {
				this.dbNotes.splice(index, 1);
				if (this.dbNotes.length) {
					this.$nextTick(() => {
						const element = this.$refs[`textarea-${index}`];
						const area = element[0].$el.querySelector("textarea");
						area.focus();
					});
				}
			}
		},
		updatedIndex(index) {
			this.activeIndex = index;
		},
	},
	mounted() {
		this.disbaleData();
		this.init(this.dbNotes);
		if (this.dbNotes.length == 0) {
			this.dbNotes = [
				{
					notes_remark: null,
				},
			];
		}
	},
};
</script>
